import { switchMap } from 'rxjs/operators';
import { AuthService } from './../../../features/authentication/service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private _localStorageService: LocalStorageService, private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const token = this._localStorageService.getToken()
    const isApiUrl = request.url.startsWith(environment.serverUrl) || request.url.startsWith(environment.paymentServiceUrl);

    if(token && isApiUrl) {

      if(request.url.includes("inventory")) {

        request = request.clone({
          setHeaders: {
            Accept: 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${token}`
          }
        })
      }
      else {
        request = request.clone({
          setHeaders: {
            Accept: 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
      }

    }

    return next.handle(request).pipe(map(data => data),
    catchError((err: HttpErrorResponse) => {

        if(err.status === 400 && err.error.message === 'jwt expired') {
          return this.handleTokenExpired(request, next);
        }  else if(err.status === 401) {
          this.router.navigate(['/auth/signin'])
        }

        return throwError(() => err)
      })
    );
  }

  private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getRefreshToken({
      refreshToken: this._localStorageService.getItem("refreshToken"),
      keepMeLoggedIn : false
    }).pipe(
      switchMap((res: any) => {
        this._localStorageService.setToken(res?.token)
        this._localStorageService.setItem('refreshToken', res?.refreshToken)
        const newAccessToken = this._localStorageService.getToken();
        if(request.url.includes("inventory")) {
          request = request.clone({
            setHeaders: {
              Accept: 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${newAccessToken}`
            }
          })
        }
        else {
          request = request.clone({
            setHeaders: {
              Accept: 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${newAccessToken}`,
              'Content-Type': 'application/json'
            }
          })
        }
        return next.handle(request);
      }),
      catchError((error) => {
        this.router.navigate(['/auth/signin']);
        this._localStorageService.removeToken();
        this._localStorageService.removeItem('upcomingPlan');
        this._localStorageService.removeItem('refreshToken');
        console.error('Error handling expired access token:', error);
        return throwError(() => error);
      })
    );
  }
}
