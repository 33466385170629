import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateLoaderService implements TranslateLoader {
  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });
  constructor(private httpClient: HttpClient) {}
  getTranslation(lang: string): Observable<any> {
    const defaultLanguage = localStorage.getItem('language') ?? lang;
    localStorage.setItem(
      'language',
      JSON.parse(JSON.stringify(defaultLanguage))
    );
    const apiAddress =
      environment.s3BucketAssets +
      `/${environment.customerId}/lang/${defaultLanguage}.json`;
    return this.httpClient
      .get(apiAddress, { headers: this.contentHeader })
      .pipe(
        catchError((_) => {
          localStorage.setItem('language', JSON.parse(JSON.stringify('en')));
          return this.httpClient.get(`/assets/i18n/en.json`);
        })
      );
  }
}
