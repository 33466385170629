import { Inject, Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  serverUrl = environment.serverUrl;
  customerId = environment.customerId;

  configDetails$ = new BehaviorSubject<any>(undefined);

  constructor( private http: HttpClient, private localStorageService : LocalStorageService, @Inject(DOCUMENT) private document: Document) {}

  getConfigFromServer(){
    const configData = JSON.parse(this.localStorageService.getItem('config') || '{}');
    this.http.get(`${this.serverUrl}/customers/setting/configuration?customerId=${ this.customerId}${configData?.cacheId ? '&cacheId='+ configData.cacheId : ''}`).subscribe((result : any) => {
      let colors;
      if(result?.data?.cacheId){
        this.localStorageService.setItem('config', JSON.stringify(result.data));
        const language = this.localStorageService.getItem('language');
        if(!language) {
          this.localStorageService.setItem('language', JSON.parse(JSON.stringify(result.data?.languages?.default)));
        }
        this.configDetails$.next(result.data);
        colors = result?.data?.webAppConfig?.colors;
      } else if(configData?.cacheId){
        this.configDetails$.next(configData);
        colors = configData?.webAppConfig?.colors;
      }
      this.updateColors(colors);
    });
  }

  getLocalConfig(){
    return this.configDetails$.asObservable();
  }

  // Your existing method for updating colors
  private updateColors(colors: any): void {
    // Access the root element and print its styles
    const root = this.document.querySelector(':root') as HTMLElement;

    if (root) {
      // Map the API response keys to the CSS variable names
      const variableMappings: { [key: string]: string } = {
        primary: '--color-primary',
        primaryLight: '--color-primary-light',
        primaryAlt: '--color-primary-alt',
        secondary: '--color-secondary',
        dark: '--color-dark',
        darkSecondary: '--color-dark-secondary',
        darkMuted: '--color-dark-muted',
        danger: '--color-danger',
        success: '--color-success',
        white: '--color-white',
        whiteLight: '--color-white-light',
        // darkDisabled: '--color-dark-disabled',
        // backgroundColor:'--background-color',
        // textDisable:'--t-disabled',
        // borderLight:'--border-light',
        // borderNavItem:'--border-nav-item',
        // primaryLightColor:'--primary-light-color',
        // errorColor:'--error-color',
        // successTextColor:'--success-color',
        // bgGray:'--background-gray',
        // successButtonBg:'--success-button-bg-color',
        // deleteButtonBg:'--delete-button-bg-color',
        // textColor:'--text-color',
        // textSecondary:'--text-secondary-color',
        // hoverBg:'--hover-background',
        // bgPrimaryLight:'--background-primary-light',
        // accordionActive:'--bs-accordion-active-color',
        // modalBg:'--modal-background-color',
        // cancelButtonBorder:'--cancel-button-border-color',
        // otpInputBorder:'--otp-input-border',
        // modalHeaderTextColor: '--modal-header-text-color',
        // modalSubHeaderTextColor: '--modal-sub-header-text-color'
      };

      // Update each color variable
      Object.keys(variableMappings).forEach((key) => {
        const variableName = variableMappings[key];
        const value = colors[key];
        root?.style?.setProperty(variableName, value);
      });

      // Log the computed styles
      // const computedStyles = getComputedStyle(root);
    }
  }

}
