import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrowserService } from './browser.service';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'any',
})
export class SubscriptionsService {
  serverUrl = environment.serverUrl;
  paymentServiceUrl = environment.paymentServiceUrl;
  customerId = environment.customerId;
  isBrowser = false;

  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private router: Router,
    private localStorageService : LocalStorageService
  ) {
    browserService
      .getIsBrowser()
      .subscribe((isBrowser: any) => (this.isBrowser = isBrowser));
  }

  subscribePlan(plan: any, rewardPoint: number | undefined, couponCode: string | undefined) {
    return new Promise((resolve, reject) => {
      const requestBody: any = { country: plan.country, platform: 'webapp'};
      if(rewardPoint) requestBody.redeemPoints = rewardPoint;
      if(couponCode) requestBody.coupon = couponCode;
      const planData = JSON.parse(this.localStorageService.getItem('plan-data')!);
      if(planData) {
        requestBody.topUpSubscriptionId = planData
      }
      // this.http
      //   .post(
      //     `${this.serverUrl}/subscriptions/checkout/${plan.stripeProductId}`,
      //     requestBody
      //   )
      //   .subscribe(
      //     (res: any) => {
      //       if (this.isBrowser && res?.url?.trim() != '') {
      //         window.location.href = res.url;
      //       }
      //       resolve(true);
      //     },
      //     (err: any) => {
      //       console.log(err);
      //       reject(err);
      //     }
      //   );
      console.log(plan);

        this.http
        .post(
          `${this.paymentServiceUrl}/payments/create/${plan._id}`,
          requestBody
        )
        .subscribe({
          next: (res: any) => {
            if(this.isBrowser && res?.url?.trim() != '') {
              if (res?.stripeIntent?.client_secret) {
                this.localStorageService.setItem('stripeKeys', JSON.stringify({
                  stripeClientSecret: res?.stripeIntent?.client_secret,
                  paymentId: res?.paymentId
                }))
                this.router.navigate(['/payment-status']);
              } else {
                window.location.href = res.url;
              }
            }
            resolve(true);
          },
          error: (err: any) => {
            reject(err);
          }
        });
    });
  }

  addUpcomingPlanForSubscription(planData: any) {
    const subscriptionApiObj:  {
      stripeProductId: any,
      country: any,
      platform: string,
      transactionId: any,
      topUpSubscriptionId?: any
    } = {
      stripeProductId: planData.stripeProductId,
      country: planData.country,
      platform: "webapp",
      transactionId: planData.transactionId,
    }
    if(planData?.topUpSubscriptionId) subscriptionApiObj.topUpSubscriptionId = planData?.topUpSubscriptionId;
    return this.http.post(`${this.serverUrl}/subscriptions`, subscriptionApiObj);
  }
}
