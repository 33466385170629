


<!-- Modal new wireframes -->
<div class="n-modal-perent g-bg-white">
    <div class="page-explore-card-shadow"></div>
    <div class="d-flex justify-content-end">
        <svg class="cursor-pointer close-btn" [ngClass]="{'btn-success': (title === 'Successful!' || type === 'Success'), 'btn-primary': title === 'Info', 'btn-full-width': (title === 'Successful!' || title === 'Info' || type === 'Success') }" (click)="close()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask id="mask0_5106_21023" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_5106_21023)">
              <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#303030"/>
            </g>
          </svg>
    </div>
    <div class="n-modal-inner">
        <p class="n-modal-heading-bold text-center" [innerHTML]="body"></p>
    </div>
    <div class="d-flex align-items-center justify-content-center">
          <img src={{countryFlag}} width="33" height="24" alt="country flag" />
          <p class="n-modal-heading-bold g-color-primary mb-0 ms-2"> {{language}}</p>
    </div>

    <p class="modal-sub-header-text mb-0 mt-2" ></p>
</div>
